var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bm-area" }, [
    _vm.column.extAttr && _vm.column.extAttr.areaNameShow === 1
      ? _c("div", [
          _c("div", { staticClass: "area-name" }, [
            _vm._v(" " + _vm._s(_vm.column.name) + " "),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "bm-area-column" },
      _vm._l(_vm.column.areas, function (area, index) {
        return _c(
          "div",
          {
            key: area.uuid,
            staticClass: "areas-content",
            style: _vm.areasStyle(area),
          },
          _vm._l(area.fields, function (col, colIndex) {
            return _c("business-column-rendering", {
              key: col.uuid,
              staticClass: "area-column",
              attrs: {
                column: col,
                businessData: _vm.businessData,
                "check-prop":
                  _vm.checkProp + ".areas." + index + ".fields." + colIndex,
              },
            })
          }),
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }