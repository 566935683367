<!--
 * @Description: 区域控件
 * @Author: 梁平贤
 * @Date: 2021/1/20 11:16
 -->
<template>
  <div class="bm-area">
    <div v-if="column.extAttr&&column.extAttr.areaNameShow===1">
      <div class="area-name" >
        {{ column.name }}
      </div>
    </div>
     <div class="bm-area-column">
    <div class="areas-content" v-for="(area,index) in column.areas" :key="area.uuid" :style="areasStyle(area)">
      <business-column-rendering
        v-for="(col, colIndex) in area.fields"
        class="area-column"
        :key="col.uuid"
        :column="col"
        :businessData="businessData"
        :check-prop="checkProp+'.areas.'+index+'.fields.'+colIndex"
      >
      </business-column-rendering>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  name: "BMAreaColumn",
  props: {
    checkProp: {
      type: String,
      default: "column"
    },
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    areasStyle(area) {
      let justify = "flex-start";
      switch (area.align) {
        case "top":
          break;
        case "middle":
          justify = "center";
          break;
        case "bottom":
          justify = "flex-end";
          break;
        default:
          break;
      }
      return { "justify-content": justify };
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../index");
  }
};
</script>

<style scoped lang="scss">
.bm-area{
  border:1px solid #e8ecf2;
  border-radius:2px;
     .area-name{
      background-color:#F2F5F8 ;
      color:#1A1C1E;
      font-weight: 600;
    height: 50px;
    border-bottom: 1px solid #e8ecf2;
    background: #F6FAFD;
    line-height: 50px;
    padding-left: 12px;
    }
.bm-area-column {
    display: flex;

border-top: none;
padding:12px;
    .areas-content {
      flex: 1;
      &:nth-child(1n+2){
        margin-left: 10px;
      }
      display: flex;
      flex-direction: column;
      .area-column {
        flex-basis: auto !important;
        &:nth-child(1n+2) {
          margin-top: 8px;
        }
      }
    }
  }
}

</style>
